body {
  background: #000;
}

div.app {
  display: grid;
  align-content: center;
  justify-items: center;
  height: 100vh;
  color: #fff;
  text-shadow: 1px 2px black;
  text-align: center;
}

img.hd-image {
  display: block;
  max-width: 100vw;
  max-height: 100vh;
  margin: auto;
}

div.tip {
  position: absolute;
  top: 33%;
  width: 100%;
  opacity: 1;
  transition: opacity 1s;
  &.fade-out {
    opacity: 0;
  }
}

div.fader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  will-change: opacity;
  transition: opacity 1s;
  &.text-hidden {
    opacity: 0;
  }
}

h1 {
  position: absolute;
  top: 3rem;
  width: 100%;
}

div.explanation {
  width: 100%;
  position: absolute;
  bottom: 0;
  cursor: pointer;
  p {
    display: block;
    max-width: 800px;
    margin: 0 auto 3rem;
  }
  &.overlay {
    top: 0;
    display: grid;
    align-content: center;
    background: rgba(100, 122, 133, 0.75);
    font-size: 1.1rem;
    line-height: 1.3;
    p {
      margin: 0 auto;
    }
  }
}
time {
  position: absolute;
  color: #dedede;
  bottom: 0.5rem;
  left: 0;
  width: 100%;
}
